import React, { useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useStatsigClient } from '@statsig/react-bindings'
import { PortableText } from '@portabletext/react'
import Image from 'next/image'
import Link from 'next/link'

import { firstIsVowel, nextImageHelper, supify } from '../../../utils'
import { CallToApply } from '../../call-to-apply'
import { CardArt } from '../../card-art'
import { CardRecommendedCredit } from '../../card-recommended-credit'
import { StarRatingEditor } from '../../star-rating-editor'
import { LoadingSkeleton } from './loading-skeleton'
import { CompareCredit } from '../../../../types'
import { CustomBadge } from '../../entity-panel/sections'
import { LinkToRedirect } from '../../link-to-redirect'
import { blockSerializers } from '../../sanity-serializers'
import { SecureSite } from '../../secure-site'
import { clickRatesAndFees } from '../../../clients/segment'
import { CreditCardLinkedData } from 'components/linked-data/card'
import cn from 'utils/classnames'
import { getSubstituteOrderId } from 'utils/orderId/getSubstituteOrderId'

const RewardList = ({
  rewards,
}: {
  rewards: CompareCredit.RewardCategoryValue[]
}) => {
  return (
    <div className="c-popup__content c-popup__arrow / absolute flex flex-col rounded-lg px-4 py-1 xs:px-6 bg-white shadow-lg z-20">
      <ul className="table table-auto border-spacing-2 border-collapse / w-full">
        {rewards.map((reward) => (
          <li
            key={reward.category._ref}
            className="table-row align-middle / border-b border-gray-300 last:border-none"
          >
            <div className="table-cell align-top pr-3 py-2">
              <b className="text-lg">
                {reward.rewardAmount}
                {reward.rewardDescription}
              </b>
            </div>
            <div className="table-cell align-top py-2">
              {reward.issuerDescription}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

const CardRewards = ({ card }: { card: CompareCredit.FormattedCard }) => {
  const matchingReward = card.rewardCategoryValues?.find(
    (item) => item.category?._ref === '4bb2bbb4-a138-4b50-90c2-2dbcc09eed03', // Everywhere Category
  )

  const rewardType = matchingReward?.rewardType || ''

  // Determine the suffix based on rewardType
  const suffix = rewardType.toLowerCase() === 'cash back' ? '%' : 'x'

  return (
    <div>
      <span className="block / mb-1 / text-base text-primary font-bold leading-tight">
        {card.rewardRateRange &&
          card.rewardRateRange.min === card.rewardRateRange.max &&
          `${card.rewardRateRange.min}${suffix} ${rewardType}`}
        {card.rewardRateRange &&
          card.rewardRateRange.min !== card.rewardRateRange.max &&
          `${card.rewardRateRange.min}${suffix} - ${card.rewardRateRange.max}${suffix} ${rewardType}`}
      </span>
    </div>
  )
}

export const Card = ({
  clientLocation,
  modalOpen,
  referencedCards,
  setModalCard,
  setModalOpen,
  tag,
  categoryTag,
  tip,
  card,
  cardOrderIds,
  position,
  modalTriggerPosition,
  recommendedCredit,
  exitModalRef,
  arrangementId,
}: {
  clientLocation: CompareCredit.AppState['clientLocation']
  modalOpen: boolean
  referencedCards: null | Record<string, CompareCredit.Entity>
  setModalCard: any
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  tag: CompareCredit.CategoryTag
  categoryTag: CompareCredit.CategoryTag
  tip: CompareCredit.CardTip
  card: CompareCredit.Entity
  cardOrderIds: Record<string, string>
  position: number
  modalTriggerPosition?: number
  recommendedCredit?: boolean
  exitModalRef?: React.RefObject<HTMLHeadingElement>
  arrangementId?: string
}) => {
  const possibleOrderId = cardOrderIds[tip.cardSlug]
  const orderId = useMemo(
    () => possibleOrderId || getSubstituteOrderId(),
    [possibleOrderId],
  )

  const currentSlug = useMemo(() => {
    if (card) {
      const currentSlug =
        card._type === 'product'
          ? card?.brand?.slug?.current
          : card?.issuer?.slug?.current

      return currentSlug || ''
    }
    return ''
  }, [card])

  const { logEvent } = useStatsigClient()

  const externalId = tag ? tag.id : '00'
  const categoryId = categoryTag ? categoryTag.id : externalId

  const isCard = card && card._type === 'card'
  const issuerName = isCard ? card.issuer.name : ''
  const issuerSecureSite = isCard ? card.issuerSecureSite : null
  const {
    buttonText,
    linkText,
    content: tipContent,
    tipContentBlockDisplay,
    tipContentBlockSelected,
  } = tip

  const [readToggle, setReadToggle] = React.useState(false)
  const handleSummaryToggle = () => {
    setReadToggle(!readToggle)
    if (readToggle == true) {
      logEvent('truncation_read_more_closed')
    } else {
      logEvent('truncation_read_more_open')
    }
  }

  const handleTruncationCtaPdp = () => {
    logEvent('truncation_cta_pdp')
  }

  /* ===== John's CONST ===== */
  const [plusIcon, setPlusIcon] = React.useState('icon-plus-black')

  const [openDetail, setOpenDetail] = React.useState<string | null>(null)

  const handleToggle = (detailName: string) => {
    setOpenDetail((prev) => (prev === detailName ? null : detailName))
  }

  return (
    <>
      {card && (card._type === 'card' || card._type === 'nonPaidCard') ? (
        <div
          id={'card-' + card.slug}
          className={`c-card-tile__wrap / inline-block w-full py-3 lg:py-4`}
          key={card.slug}
        >
          <h2
            className={cn(
              `c-card-tile__header / mb-3 / text-xl font-bold font-sans text-center md:text-left leading-tight`,
            )}
            ref={position === modalTriggerPosition ? exitModalRef : null}
          >
            {card._type === 'card' ? (
              <LinkToRedirect
                externalId={externalId}
                linkParams={card.applyNowLinkParameters}
                orderId={orderId}
                position={position}
                slug={card.slug}
              >
                <span
                  className="text-primary-mid / hover:text-primary-bright"
                  dangerouslySetInnerHTML={{
                    __html: supify(card.name),
                  }}
                />
              </LinkToRedirect>
            ) : (
              <Link href={card.moreInfoLink}>
                <span
                  className="text-primary-mid / hover:text-primary-bright"
                  dangerouslySetInnerHTML={{
                    __html: supify(card.name),
                  }}
                />
              </Link>
            )}
          </h2>
          <div
            className="c-card-tile / relative / flex flex-row items-start flex-wrap /
            max-w-md md:w-80 / md:float-left /
            mb-6 mx-auto md:mr-10 /
            bg-white shadow-lg rounded-lg leading-normal"
          >
            <div className="c-card-tile__top / flex flex-col flex-auto / px-4 / py-3">
              {card.customBadge && (
                <div className="c-card-tile__badge / w-full / -mt-2 lg:mb-8">
                  <CustomBadge text={card.customBadge} theme="center" />
                </div>
              )}
              <div className="flex flex-row flex-wrap justify-center / w-full / mb-2 xs:mb-3 md:mb-1">
                <div className="c-card-art / text-center flex items-start justify-center / w-full max-w-[12rem] / mb-2">
                  <div className="relative / w-full / rounded-sm shadow / overflow-hidden">
                    {isCard ? (
                      <LinkToRedirect
                        externalId={externalId}
                        linkParams={card.applyNowLinkParameters}
                        orderId={orderId}
                        position={position}
                        slug={card.slug}
                      >
                        <div className="relative flex rounded-sm overflow-hidden">
                          <span className="c-card-art__content / absolute z-10 left-0 / flex flex-wrap content-center items-center / w-full h-full text-white">
                            <span className="c-card-art__icon / relative / h-8 w-full / mb-2">
                              <Image
                                alt="Lock Icon"
                                src="/static/icons/icon-lock-round-w.svg"
                                fill
                                sizes="100vw"
                              />
                            </span>
                            <span className="w-full font-bold">
                              {buttonText || 'Apply Now'}
                            </span>
                          </span>
                          <CardArt
                            _rev={card._rev}
                            cardArt={card.cardArt}
                            imgClasses="relative z-0 w-full h-full object-cover top-0"
                            customCodeSnippets={card.customCodeSnippets}
                            issuer={currentSlug}
                            name={card.name}
                            orderId={orderId}
                            slug={card.slug}
                            categoryId={categoryId}
                            externalId={externalId}
                            position={position}
                            sortable
                            sort={card?.context?.sort}
                            arrangementId={arrangementId}
                          />
                        </div>
                      </LinkToRedirect>
                    ) : (
                      <Link href={card.moreInfoLink}>
                        <div className="relative flex rounded-sm overflow-hidden">
                          <span className="c-card-art__content / absolute z-10 left-0 / flex flex-wrap content-center items-center / w-full h-full text-white">
                            <span className="w-full font-bold">Learn More</span>
                          </span>
                          <CardArt
                            _rev={card._rev}
                            cardArt={card.cardArt}
                            imgClasses="relative z-0 w-full h-full object-cover top-0"
                            customCodeSnippets={card.customCodeSnippets}
                            issuer={currentSlug}
                            name={card.name}
                            orderId={orderId}
                            slug={card.slug}
                            categoryId={categoryId}
                            externalId={externalId}
                            position={position}
                            sortable
                            arrangementId={arrangementId}
                          />
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
                <div className="c-card-tile__col / flex flex-col items-center justify-center / w-full">
                  <p className="c-card-tile__name / text-base font-bold text-center">
                    {card._type === 'card' ? (
                      <LinkToRedirect
                        externalId={externalId}
                        linkParams={card.applyNowLinkParameters}
                        orderId={orderId}
                        position={position}
                        slug={card.slug}
                      >
                        <span
                          className="text-primary-mid / hover:text-primary-bright"
                          dangerouslySetInnerHTML={{
                            __html: supify(card.name),
                          }}
                        />
                      </LinkToRedirect>
                    ) : (
                      <Link href={card.moreInfoLink}>
                        <span
                          className="text-primary-mid / hover:text-primary-bright"
                          dangerouslySetInnerHTML={{
                            __html: supify(card.name),
                          }}
                        />
                      </Link>
                    )}
                  </p>
                  <div className="c-card-tile__rating / -ml-3 / scale-[85%] lg:scale-[90%]">
                    <StarRatingEditor
                      productSlug={card.slug}
                      stars={card.expertReviewRating}
                    />
                  </div>
                </div>
              </div>

              <div className="c-card-tile__cta / w-full">
                <div
                  className={`w-full / ${
                    isCard &&
                    card.phoneNumber &&
                    isMobileOnly &&
                    clientLocation.country === 'US'
                      ? 'mb-1.5'
                      : 'mb-2'
                  }`}
                >
                  {isCard ? (
                    <>
                      <LinkToRedirect
                        externalId={externalId}
                        linkParams={card.applyNowLinkParameters}
                        orderId={orderId}
                        position={position}
                        slug={card.slug}
                      >
                        <div className="c-btn c-btn--primary / inline-block / w-full max-w-none md:max-w-xs py-1.5 / text-center text-lg">
                          {buttonText || 'Apply Now'}
                          <span className="c-btn__icon / relative top-0.5 / inline-block / h-4 w-4 / ml-2">
                            <Image
                              alt="Lock Icon"
                              src="/static/icons/icon-lock-round-w.svg"
                              fill
                              sizes="100vw"
                              aria-hidden={true}
                            />
                          </span>
                        </div>
                      </LinkToRedirect>
                    </>
                  ) : (
                    <Link href={card.moreInfoLink}>
                      <div
                        className={`c-btn c-btn--secondary  / inline-block / w-full max-w-none md:max-w-xs py-1.5 / text-center text-lg / trk_card-detail_cta trk_card-detail_cta_${card.slug}`}
                      >
                        Learn More
                      </div>
                    </Link>
                  )}
                </div>
                <p className="w-full / text-fs13 text-gray-600 / text-center leading-tight / mb-2.5">
                  {isCard && (
                    <SecureSite
                      issuerName={issuerName}
                      issuerSecureSite={issuerSecureSite}
                    />
                  )}
                </p>
                {isCard && card.ratesAndFees && card.ratesAndFees !== 'N/A' && (
                  <div className="w-full mb-1 / text-center leading-tight">
                    <a
                      className="text-primary-bright text-xs"
                      href={card.ratesAndFees}
                      onClick={() => {
                        clickRatesAndFees({
                          applyNowLink: card.applyNowLink,
                          component: 'Card',
                          name: card.name,
                          url: window.location.href,
                        })
                      }}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Rates &amp; Fees
                    </a>
                    <>
                      {card.issuer.slug.current === 'american-express' && (
                        <p className="text-fs10 text-gray-600 / text-center / mb-3 md:mb-1 / w-full leading-4">
                          Terms Apply
                        </p>
                      )}
                    </>
                  </div>
                )}
                {isCard &&
                  card.phoneNumber &&
                  isMobileOnly &&
                  clientLocation.country === 'US' && (
                    <div className="w-full mt-3">
                      <p className="mb-3 text-center">
                        <CallToApply
                          classes="max-w-xs"
                          entity={card as CompareCredit.FormattedCard}
                        />
                      </p>

                      <p className="text-xs text-gray-600 / text-center / -mt-1 mb-4 / md:self-start w-full max-w-xs leading-4">
                        with {firstIsVowel(issuerName) ? 'an' : 'a'}{' '}
                        {issuerName} representative
                      </p>
                      {isCard &&
                        card.ratesAndFees &&
                        card.ratesAndFees !== 'N/A' && (
                          <div className="w-full mb-1 / text-center leading-tight">
                            <a
                              className="text-primary-bright text-xs"
                              href={card.ratesAndFees}
                              onClick={() => {
                                clickRatesAndFees({
                                  applyNowLink: card.applyNowLink,
                                  component: 'Card',
                                  name: card.name,
                                  url: window.location.href,
                                })
                              }}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Rates &amp; Fees
                            </a>
                            <>
                              {card.issuer.slug.current ===
                                'american-express' && (
                                <p className="text-fs10 text-gray-600 / text-center / mb-3 md:mb-1 / w-full leading-4">
                                  Terms Apply
                                </p>
                              )}
                            </>
                          </div>
                        )}
                    </div>
                  )}
              </div>
            </div>
            <div className="c-card-tile__highlights / relative / w-full / border-t border-b border-gray-300">
              <ul className="c-card-tile__list / flex flex-row flex-wrap / md:flex md:flex-wrap lg:flex / text-xs xxs:text-fs13 sm:text-sm  lg:text-sm / md:text-fs13">
                <li className="c-card-tile__item / flex flex-col items-stretch justify-between  shrink-0 / w-1/2 / px-2 py-3 / text-center leading-snug / border-r border-b border-slate-200">
                  <div>
                    <span className="text-slate-500">Welcome Offer</span>
                    <span className="block / mb-1 / text-base text-primary font-bold leading-tight">
                      {card.welcomeOfferValue ? card.welcomeOfferValue : 'N/A'}
                    </span>
                  </div>
                  <div className="c-popup / relative / w-full">
                    {card.bonusSignUp && (
                      <button
                        className="text-slate-600 underline"
                        onClick={() => handleToggle('detailsOpen1')}
                      >
                        Details
                      </button>
                    )}
                    <div
                      className={`c-popup__container / absolute top-full / cursor-default / w-full min-w-min / mt-0.5 / font-normal text-left text-gray-700 / z-40 
            ${openDetail === 'detailsOpen1' ? 'block' : 'hidden'}
          `}
                    >
                      <div className="c-popup__content c-popup__arrow / relative / flex justify-center / rounded-lg px-3 py-2 bg-white shadow-lg / z-20">
                        {card.bonusSignUp ? card.bonusSignUp : 'N/A'}
                      </div>
                    </div>
                  </div>
                </li>
                <li className="c-card-tile__item / flex flex-col items-stretch justify-between shrink-0 / w-1/2 / px-2 py-3 / text-center leading-snug / border-b border-slate-200">
                  <div>
                    <span className="text-slate-500">Annual Fee</span>
                    <span className="block / mb-1 / text-base text-primary font-bold leading-tight">
                      {card.feeAnnual ? card.feeAnnual : 'N/A'}
                    </span>
                  </div>
                </li>
                <li className="c-card-tile__item / flex flex-col items-stretch justify-between  shrink-0 / w-1/2 / px-2 py-3 / text-center leading-snug / border-r border-slate-200">
                  <div>
                    <span className="text-slate-500">Rewards Rate</span>
                    <span className="block / mb-1 / text-lg text-primary font-bold leading-tight">
                      {card.rewardRateRange ? (
                        <CardRewards
                          card={card as CompareCredit.FormattedCard}
                        ></CardRewards>
                      ) : (
                        'N/A'
                      )}
                    </span>
                  </div>
                  <div className="c-popup / relative / w-full">
                    {card.rewardCategoryValues && (
                      <button
                        className="text-slate-600 underline"
                        onClick={() => handleToggle('detailsOpen2')}
                      >
                        Details
                      </button>
                    )}
                    <div
                      className={`c-popup__container c-popup__arrow--grid / absolute top-full / cursor-default / w-full min-w-64 / mt-0.5 / font-normal text-left text-gray-700 / z-40 
                        ${openDetail === 'detailsOpen2' ? 'block' : 'hidden'}
          `}
                    >
                      {card.rewardCategoryValues ? (
                        <>
                          <RewardList
                            rewards={card.rewardCategoryValues}
                          ></RewardList>
                        </>
                      ) : (
                        'N/A'
                      )}
                    </div>
                  </div>
                </li>
                <li className="c-card-tile__item / flex flex-col items-stretch justify-between  shrink-0 / w-1/2 / px-2 py-3 / text-center leading-snug">
                  <div>
                    <span className="text-slate-500">Recommended Credit</span>
                    <span className="block / mb-1 / text-base text-primary font-bold leading-tight">
                      {card.creditRange
                        ? `${card.creditRange.min}-${card.creditRange.max}`
                        : 'N/A'}
                    </span>
                  </div>
                  <div className="c-popup / relative / w-full">
                    <button
                      className="text-slate-600 underline"
                      onClick={() => handleToggle('detailsOpen3')}
                    >
                      More Info
                    </button>
                    <div
                      className={`c-popup__container / absolute top-full / cursor-default / w-full min-w-min / mt-0.5 / font-normal text-left text-gray-700 / z-40 
                    ${openDetail === 'detailsOpen3' ? 'block' : 'hidden'}`}
                    >
                      <div className="c-popup__content c-popup__arrow  / relative / flex justify-center / rounded-lg px-3 py-2 bg-white shadow-lg / z-20">
                        Credit ranges are a variation of FICO Score 8, one of
                        many types of credit scores lenders may use when
                        considering your credit card application.
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="w-full px-4 py-4">
              <button
                onClick={() => {
                  setModalCard(card)
                  setModalOpen(!modalOpen)
                }}
                onKeyDown={() => {
                  setModalCard(card)
                  setModalOpen(!modalOpen)
                }}
                onMouseEnter={() => {
                  setPlusIcon('icon-plus-primary-bright')
                }}
                onMouseLeave={() => {
                  setPlusIcon('icon-plus-black')
                }}
                tabIndex={0}
                className="flex w-full items-center justify-center / py-2 / text-center text-gray-700 text-fs13 / 
                border border-gray-400 rounded-sm / hover:text-primary-bright hover:border-primary-bright group"
              >
                More Details
                <span className="relative / h-3 w-3 / ml-2 / opacity-40 group-hover:opacity-100">
                  <Image
                    alt="Expand Plus Icon"
                    src={`/static/icons/${plusIcon}.svg`}
                    fill
                    sizes="100vw"
                  />
                </span>
              </button>
            </div>
            <div className="hidden">
              {recommendedCredit ? (
                <div className="flex items-stretch justify-center / w-full mb-2.5 / text-center">
                  <CardRecommendedCredit card={card} maxW="max-w-none" />
                </div>
              ) : (
                'N/A'
              )}
              <div
                className={`w-full / ${
                  isCard &&
                  card.phoneNumber &&
                  isMobileOnly &&
                  clientLocation.country === 'US'
                    ? 'mb-1.5'
                    : 'mb-2'
                }`}
              >
                {isCard ? (
                  <LinkToRedirect
                    externalId={externalId}
                    linkParams={card.applyNowLinkParameters}
                    orderId={orderId}
                    position={position}
                    slug={card.slug}
                  >
                    <div className="c-btn c-btn--primary / inline-block / w-full max-w-none md:max-w-xs py-1.5 / text-center text-lg">
                      {buttonText || 'Apply Now'}
                      <span className="c-btn__icon / relative top-0.5 / inline-block / h-4 w-4 / ml-2">
                        <Image
                          alt="Lock Icon"
                          src="/static/icons/icon-lock-round-w.svg"
                          fill
                          sizes="100vw"
                        />
                      </span>
                    </div>
                  </LinkToRedirect>
                ) : (
                  <Link href={card.moreInfoLink}>
                    <div
                      className={`c-btn c-btn--secondary  / inline-block / w-full max-w-none md:max-w-xs py-1.5 / text-center text-lg / trk_card-detail_cta trk_card-detail_cta_${card.slug}`}
                    >
                      Learn More
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
          {isMobileOnly ? (
            <div>
              <div
                className={`c-card-content / c-slidedown / relative md:contents ${
                  readToggle ? '' : 'c-slidedown--hidden'
                }`}
              >
                {referencedCards &&
                  tip &&
                  (tipContentBlockDisplay
                    ? tipContentBlockSelected.content.map(
                        (contentBlock: any, idx: number) => {
                          return (
                            <PortableText
                              key={idx}
                              value={contentBlock}
                              components={blockSerializers({
                                cardOrderIds,
                                categoryId,
                                externalId,
                                referencedCards,
                              })}
                            />
                          )
                        },
                      )
                    : tipContent.map((content: any, idx: number) => {
                        return (
                          <PortableText
                            key={idx}
                            value={content}
                            components={blockSerializers({
                              cardOrderIds,
                              categoryId,
                              externalId,
                              referencedCards,
                            })}
                          />
                        )
                      }))}

                <div className="flex flex-col items-start justify-start / mb-5 / md:hidden">
                  <Link
                    href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
                  >
                    <button
                      className="c-btn c-btn--secondary / inline-block / px-6 / text-white leading-snug text-inherit / hover:bg-primary-mid"
                      onClick={handleTruncationCtaPdp}
                    >
                      See More Details
                    </button>
                  </Link>
                </div>
                <div
                  className={`c-summary__bg-gradient / absolute z-2 / bottom-0 left-0 / w-full h-1/3 / bg-gradient-to-t from-white / opacity-90 / md:hidden ${
                    readToggle ? 'hidden' : null
                  }`}
                ></div>
              </div>

              <button
                className="flex flex-row items-center / text-sm text-primary-bright-dark font-semibold / hover:text-primary-bright hover:underline / md:hidden"
                onClick={handleSummaryToggle}
                aria-label={`${readToggle ? 'Show' : 'Hide'} summary`}
                aria-expanded={readToggle ? false : true}
                aria-controls="our-pick-summary"
              >
                {readToggle ? 'Read Less' : 'Read More'}{' '}
                {readToggle ? (
                  <span className="inline-block w-4 ml-1">
                    <Image
                      alt="expand"
                      src="/static/icons/icon-minus-primary-bright.svg"
                      height={24}
                      width={24}
                      aria-hidden={true}
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                    />
                  </span>
                ) : (
                  <span className="inline-block w-4 ml-1">
                    <Image
                      alt="expand"
                      src="/static/icons/icon-plus-primary-bright.svg"
                      height={24}
                      width={24}
                      aria-hidden={true}
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                    />
                  </span>
                )}
              </button>
            </div>
          ) : (
            <div>
              {referencedCards &&
                tip &&
                (tipContentBlockDisplay
                  ? tipContentBlockSelected.content.map(
                      (contentBlock: any, idx: number) => {
                        return (
                          <PortableText
                            key={idx}
                            value={contentBlock}
                            components={blockSerializers({
                              cardOrderIds,
                              categoryId,
                              externalId,
                              referencedCards,
                            })}
                          />
                        )
                      },
                    )
                  : tipContent.map((content: any, idx: number) => {
                      return (
                        <PortableText
                          key={idx}
                          value={content}
                          components={blockSerializers({
                            cardOrderIds,
                            categoryId,
                            externalId,
                            referencedCards,
                          })}
                        />
                      )
                    }))}
            </div>
          )}
          <CreditCardLinkedData card={card} />
        </div>
      ) : (card as CompareCredit.ProductGeneric) && card._type === 'product' ? (
        <div
          className={`c-card-tile__wrap / inline-block w-full py-3 lg:py-4`}
          key={card.slug}
        >
          <h2
            className={`
              c-card-tile__header / text-xl font-bold font-sans text-center md:text-left leading-tight mb-2 md:mb-4

              `}
            ref={position === modalTriggerPosition ? exitModalRef : null}
          >
            <LinkToRedirect
              externalId={externalId}
              linkParams={card.linkParameters}
              orderId={orderId}
              slug={card.slug}
            >
              <div className="text-primary-mid / hover:text-primary-bright">
                {card.name}
                <sup>&trade;</sup>
              </div>
            </LinkToRedirect>
          </h2>
          <div className="c-card-tile / relative m-auto max-w-xs md:w-72 md:float-left / mb-6 md:mr-10 md:mt-8 / px-6 py-5 / bg-white shadow-lg rounded-lg">
            <div className="c-card-tile__logo / w-10/12 / mb-3 mx-auto">
              <LinkToRedirect
                externalId={externalId}
                linkParams={card.linkParameters}
                orderId={orderId}
                slug={card.slug}
              >
                <div>
                  <CardArt
                    _rev={card._rev}
                    cardArt={card.image}
                    imgClasses="relative z-0 w-full h-full object-cover top-0"
                    issuer={currentSlug}
                    name={card.name}
                    orderId={orderId}
                    slug={card.slug}
                    categoryId={categoryId}
                    externalId={externalId}
                    position={position}
                    sortable
                    sort={card?.context?.sort}
                    arrangementId={arrangementId}
                  />
                </div>
              </LinkToRedirect>
            </div>

            <LinkToRedirect
              externalId={externalId}
              linkParams={card.linkParameters}
              orderId={orderId}
              slug={card.slug}
            >
              <div className="c-btn c-btn--primary max-w-xs py-1 text-lg">
                {linkText ? (
                  <>
                    <span>{linkText}</span>
                    <span className="c-btn__icon / relative top-0.5 / inline-block / h-4 w-4 / ml-2">
                      <Image
                        alt="Lock Icon"
                        src="/static/icons/icon-lock-round-w.svg"
                        fill
                        sizes="100vw"
                      />
                    </span>
                  </>
                ) : (
                  'Sign Up'
                )}
              </div>
            </LinkToRedirect>
          </div>
          {referencedCards &&
            tip &&
            tip.content &&
            tip.content.map((content: any, idx: number) => {
              return (
                <PortableText
                  key={idx}
                  value={content}
                  components={blockSerializers({
                    cardOrderIds,
                    categoryId,
                    externalId,
                    referencedCards,
                  })}
                />
              )
            })}
        </div>
      ) : (
        <LoadingSkeleton n={1} />
      )}

      <style jsx>{`
        .c-card-tile__logo {
          max-width: 11rem;
        }
        .c-slidedown.c-slidedown--hidden {
          min-height: 14rem;
          max-height: 14rem;
          opacity: 1;
        }
        @media (min-width: 768px) {
          .c-slidedown,
          .c-slidedown.c-slidedown--hidden {
            height: auto;
            min-height: auto;
            max-height: none;
            opacity: 1;
          }
        }
      `}</style>
    </>
  )
}
