import { PortableText, PortableTextBlockComponent } from '@portabletext/react'
import propOr from 'lodash/fp/propOr'
import Image from 'next/image'
import Link from 'next/link'
import { createElement, useMemo } from 'react'

import type { CompareCredit } from '../../../types/compare-credit'
import { nextImageHelper, supify } from '../../utils'
import { generateNewOrderId } from '../../utils/orderId/generateNewOrderId'
import { AddToCompareBtn2, StarRatingEditor } from '../'
import { CardArt } from '../card-art'

const Normal: PortableTextBlockComponent = ({ children }) => (
  <span>{children}</span>
)

const getAttributeRenderer = (card: CompareCredit.FormattedCard) => {
  const AttributeRenderer: React.FC<{
    value: { attribute: keyof CompareCredit.Entity }
  }> = ({ value: { attribute } }) => {
    return createElement('span', {
      dangerouslySetInnerHTML: {
        __html: supify(propOr('', attribute, card)?.toString()),
      },
    })
  }

  return AttributeRenderer
}

export const CardTile: React.FC<{
  card: CompareCredit.FormattedCard
  classes?: string
  showStars: boolean
  comparisonCart: CompareCredit.FormattedCard[]
  listItems: any[]
  updateCart: (
    action: 'add' | 'remove',
    cards: CompareCredit.FormattedCard[],
  ) => void
}> = ({
  card,
  classes = '',
  comparisonCart,
  showStars,
  updateCart,
  listItems,
}) => {
  const orderId = useMemo(() => generateNewOrderId(), [])

  const AttributeRenderer = useMemo(() => getAttributeRenderer(card), [card])

  const cardIsInCart = useMemo(
    () =>
      comparisonCart.some(
        (comparisonCartCard) => comparisonCartCard.name === card.name,
      ),
    [comparisonCart, card],
  )

  const handleUpdateCart = (action: 'add' | 'remove') => {
    updateCart(action, [card])
  }

  return (
    <div
      className={`flex md:items-stretch / w-full md:w-1/3 / max-w-sm md:max-w-none ${classes}`}
    >
      <div className="relative flex flex-col justify-between / w-full / text-center items-center justify-center / mb-4 / p-3 xs:p-5 md:p-3 lg:p-5 xl:p-6 / rounded-sm shadow">
        <div className="relative / flex flex-col / w-full">
          <div className="flex flex-row items-start">
            <div className="flex-shrink-0 / w-1/4 md:w-1/3 lg:w-1/4">
              <div className="c-card-art / text-center flex items-center justify-center / mb-1">
                <div className="relative / w-full / rounded-sm shadow">
                  <Link
                    href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
                    className="relative flex / w-full"
                  >
                    <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white text-fs11 leading-snug">
                      <span className="c-card-art__icon / w-full mb-2 hidden">
                        <span className="inline-block w-8">
                          <Image
                            height={32}
                            width={32}
                            alt="icon lock"
                            src="/static/icons/icon-lock-round-w.svg"
                            style={nextImageHelper.responsive}
                            sizes="100vw"
                          />
                        </span>
                      </span>
                      <span className="w-full font-semibold">Learn More</span>
                    </span>
                    <CardArt
                      _rev={card._rev}
                      cardArt={card.cardArt}
                      customCodeSnippets={card.customCodeSnippets}
                      imgClasses="relative z-0 w-full h-full object-cover top-0"
                      issuer={card.issuer.slug.current}
                      name={card.name}
                      slug={card.slug}
                      categoryId={null}
                      externalId={null}
                      orderId={orderId}
                    />
                  </Link>
                </div>
              </div>
              {card.issuer.slug.current === 'american-express' && (
                <p className="text-fs10 text-gray-600 / text-center / mb-2 md:mb-1 / w-full leading-4">
                  Terms Apply
                </p>
              )}
            </div>

            <div className="pl-3">
              <h3 className="font-sans font-bold text-fs13 leading-tight text-left / mb-1">
                <Link
                  href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
                  className="c-card-title / text-primary-mid hover:text-primary-bright / js-card-title"
                  dangerouslySetInnerHTML={{ __html: supify(card.name) }}
                />
              </h3>

              {showStars && (
                <div className="flex justify-start w-full">
                  <StarRatingEditor
                    productSlug={card.slug}
                    stars={card.expertReviewRating}
                  />
                </div>
              )}
            </div>
          </div>
          <ul className="c-list-none / mb-4 / text-xs text-gray-700 / border-t-2 border-gray-200">
            {listItems.map((item, i) => {
              return (
                <li
                  key={i}
                  className={`flex flex-row flex-wrap justify-between / py-1.5 / text-left / ${
                    ++i !== listItems.length && 'border-b border-gray-200'
                  }`}
                >
                  <label className="inline-block / w-full / mb-0.5 / font-bold">
                    {item.Title}
                  </label>
                  <span>
                    <PortableText
                      value={item.Description}
                      components={{
                        block: { normal: Normal },
                        types: {
                          cardAttribute: AttributeRenderer,
                        },
                      }}
                    />
                  </span>
                </li>
              )
            })}
          </ul>
        </div>

        <p className="w-full / text-center">
          <AddToCompareBtn2
            inCart={cardIsInCart}
            updateCart={handleUpdateCart}
          />
        </p>
      </div>
    </div>
  )
}
