import Image from 'next/image'
import { useContext, useEffect, useMemo, useState } from 'react'

import { clickPromotion, clickRatesAndFees } from 'clients/segment'
import { LinkToRedirect } from 'components/'
import { AdvertiserDisclaimerTop } from 'components/advertiser-disclaimer-top'
import { CardArt } from 'components/card-art'
import { LabelAd } from 'components/label-ad'
import { SecureSite } from 'components/secure-site'
import { StarRatingEditor } from 'components/star-rating-editor'
import { UserContext } from 'components/UserContext'
import { nextImageHelper, supify } from 'utils/'
import cn from 'utils/classnames'
import { generateNewOrderId } from 'utils/orderId/generateNewOrderId'
import type { CompareCredit } from '../../../types'
import { AlertUpsell } from './alert-upsell'
import CategoryAlert from './alert'
import NavLink from './nav-link'
import { useNavData } from './navItems'
import MenuItem from './menu-item'
import MinimalNav from './minimal-nav'
import type { NavData, NavItem } from './types'

const navLinkColor = '#232D42'

export const NavBar: React.FC<{
  alert: {
    height: number
    message: any[] | undefined
    setHeight: React.Dispatch<React.SetStateAction<number>>
    active: boolean
    show: boolean
    setShowAlert: React.Dispatch<React.SetStateAction<boolean>>
  }
  banner: React.ReactNode | undefined
  disclaimerAlignment?: 'left' | 'right' | 'top' | string
  hideAsSeenIn?: boolean
  hideBBBIcon?: boolean
  lightTheme?: boolean
  mixTheme?: boolean
  onHome?: boolean
  setShowMobileNav: (active: boolean) => void
  showMobileNav: boolean
  setSelectedSubMenu: (arg0: string | null) => void
  navType?: string
  setShowAlertUpsell: React.Dispatch<React.SetStateAction<boolean>>
  upsellBanner?: CompareCredit.NavUpsellBanner
  slug?: string
}> = ({
  alert,
  banner,
  disclaimerAlignment,
  hideAsSeenIn,
  hideBBBIcon,
  lightTheme,
  mixTheme,
  onHome,
  setSelectedSubMenu,
  setShowMobileNav,
  showMobileNav,
  navType,
  setShowAlertUpsell,
  upsellBanner,
}) => {
  const [browserWidth, setBrowserWidth] = useState<number | null>(null)
  const [hasScrolled, setHasScrolled] = useState(false)
  const [userClosedAlert, setUserClosedAlert] = useState(false)

  const navData = useNavData()

  // when alert.height changes, listen for scroll & browser width changes
  useEffect(() => {
    // browser width event listener
    setBrowserWidth(window.innerWidth)

    const handleResize = () => setBrowserWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)

    // scroll event listener
    const handleScroll = () => {
      setHasScrolled(window.pageYOffset > 0)

      if (window.pageYOffset > alert.height) {
        alert.setShowAlert(false)
      } else if (!userClosedAlert) {
        alert.setShowAlert(alert.active)
      }
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [alert.height, alert.active])

  // when alert or browserWidth changes, get height of alert bar
  useEffect(() => {
    const timeout = setTimeout(() => {
      const alertEl = document.getElementById('category-alert')
      if (alertEl && alert.show) {
        alert.setHeight(alertEl.offsetHeight)
      }
    }, 100)
    return () => clearTimeout(timeout)
  }, [alert.show, browserWidth])

  // If user clicks escape key, hide mobile nav
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        event.preventDefault()
        setSelectedSubMenu(null)
        setShowMobileNav(!showMobileNav)
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  })

  useEffect(() => {
    setShowAlertUpsell(Boolean(upsellBanner?.includeUpsellBanner))
  }, [upsellBanner?.includeUpsellBanner])

  return (
    <div
      id="navbar-container"
      className={cn(
        'c-navbar-container / fixed transition-25s z-60 w-full c-nav-main--shadow',
        {
          'flex flex-col': alert.show && alert.message,
          'c-nav-main--transparent': onHome && !hasScrolled && !showMobileNav,
          'c-nav-main--light':
            lightTheme && onHome && !hasScrolled && !showMobileNav,
          'c-nav-main--mix-theme':
            mixTheme && onHome && !hasScrolled && !showMobileNav,
          'c-nav-main--transparent shadow-none':
            onHome && !hasScrolled && !showMobileNav,
          'c-nav-main--shadow bg-white z-70': hasScrolled,
          'bg-white': showMobileNav,
          'c-nav-main--minimal': navType === 'as-seen-in',
          'c-nav-main--minimal-bbb': navType === 'minimal-bbb',
          'c-nav-main--minimal c-nav-main--minimal-gray':
            navType === 'as-seen-in-gray',
          'c-nav-main--empty': navType === 'empty',
          'c-nav-main--minimal c-nav-main--dark-blue': navType === 't-dark-nav',
        },
      )}
    >
      {disclaimerAlignment == 'top' && <AdvertiserDisclaimerTop />}
      {banner}
      <CategoryAlert
        alert={{
          message: alert.message,
          setHeight: alert.setHeight,
          show: alert.show,
          setShowAlert: alert.setShowAlert,
          setUserClosedAlert: setUserClosedAlert,
        }}
      />
      {!showMobileNav && upsellBanner?.includeUpsellBanner ? (
        <AlertUpsell
          upsellBanner={upsellBanner}
          setShowAlertUpsell={setShowAlertUpsell}
          promotion={{ id: upsellBanner.card.slug, name: 'cat.banner' }}
        />
      ) : (
        <></>
      )}
      <div
        className={`c-nav-main / h-16 lg:h-20 lg:py-1 lg:px-8 w-full
      ${showMobileNav ? 'is-open' : ''} ${!onHome ? 'bg-white' : ''}
      flex `}
      >
        <nav className="c-nav-main__inner / w-full flex items-center justify-between mx-auto">
          <div
            className={`c-nav-main__bbb-mobile w-16 pl-4 xs:pl-5 / lg:hidden `}
          >
            {!hideBBBIcon && <BbbMobile />}
          </div>

          <div className="c-nav-main__logo / flex items-center justify-center / shrink-0 order-1">
            <a
              className="c-nav-main__logo-link / w-[128px] lg:w-[144px] / inline-block cursor-pointer"
              href="/"
              aria-label="Compare Credit's home page"
            >
              <span className="cc-logo cc-logo--white">
                <Image
                  src="/static/logos/compare-credit-light.svg"
                  alt="Compare Credit logo"
                  width={208}
                  height={67}
                  priority={true}
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                />
              </span>
              <span className="cc-logo cc-logo--base / w-auto">
                <Image
                  src="/static/logo.svg"
                  width={144}
                  height={47}
                  alt="Compare Credit logo"
                  priority={true}
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                />
              </span>
            </a>
            <div className="cc-logo__duo / ml-4 sm:ml-5 pl-2 sm:pl-5 border-l border-slate-300 / hidden">
              <div className="cc-logo__duo-bl / shrink-0 w-10 sm:w-[6.5rem] lg:w-[7.5rem]">
                <Image
                  src="/static/logos/bl_logomark_base.svg"
                  width={144}
                  height={47}
                  alt="Business Loans"
                  priority={true}
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                  className="cc-logo__duo-bl--mobile sm:hidden"
                />
                <Image
                  src="/static/logos/bl_logo_base.svg"
                  width={144}
                  height={47}
                  alt="Business Loans"
                  priority={true}
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                  className="cc-logo__duo-bl--mobile hidden sm:block"
                />
              </div>
            </div>
          </div>

          <div className="c-nav-main__lg-links / hidden lg:flex justify-end / w-full / order-2">
            <div className="flex">
              <div>
                <NavLinkContainer>
                  <CardCategory navData={navData} />
                  <CardIssuer navData={navData} />
                  <CreditRange navData={navData} />
                </NavLinkContainer>
              </div>
            </div>
          </div>

          <div className="c-nav-main__col / flex flex-row items-center justify-start shrink-0 / order-3">
            <Bbb />
            <button
              className={`c-nav-toggle / relative / flex items-center justify-center lg:hidden / h-16 w-16 / lg:ml-5 / cursor-pointer / ${
                showMobileNav ? 'is-open' : ''
              }`}
              onClick={() => {
                setSelectedSubMenu(null)
                setShowMobileNav(!showMobileNav)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setSelectedSubMenu(null)
                  setShowMobileNav(!showMobileNav)
                }
              }}
              tabIndex={0}
              type="button"
              aria-haspopup="true"
              aria-expanded={showMobileNav ? true : false}
              aria-label="Toggle mobile menu"
              aria-controls="mobile-nav"
            >
              <div className={`c-nav-toggle__inner / relative`}>
                <span className="c-nav-toggle__brdr first / rounded-t-full rounded-b-full"></span>
                <span className="c-nav-toggle__brdr mid / rounded-t-full rounded-b-full"></span>
                <span className="c-nav-toggle__brdr last / rounded-t-full rounded-b-full"></span>
              </div>
            </button>
          </div>
          {!hideAsSeenIn && <MinimalNav />}
        </nav>
      </div>
    </div>
  )
}

const CardCategory: React.FC<{ navData: NavData }> = ({ navData }) => {
  // DEV NOTE: Variable to display the nav upsell card
  const { navUpsellCard } = useContext(UserContext)

  const mainNavItems = navData?.['credit-cards'].sections?.main.items

  const navLinkHref = navData['credit-cards'].href || '#'

  return (
    <NavLink
      // text={navData['credit-cards'].text}
      text="Card Category"
      href={navLinkHref}
      i={0}
      menu="credit-card"
    >
      <div className="c-cc-dropdown / shrink-0 flex / w-full px-7 py-6">
        <div className="c-cc-dropdown__content / flex / w-full">
          <div className="c-cc-dropdown__innner upsell / flex items-center / w-full">
            <div className="flex flex-shrink / w-full">
              <div className="c-cc-dropdown__col / flex-grow">
                {mainNavItems
                  ?.slice(0, 4)
                  .map(({ text, icon, href }: NavItem, i: number) => {
                    return (
                      <MenuItem href={href} key={i} icon={icon} text={text} />
                    )
                  })}
              </div>
              <div className="c-cc-dropdown__col / flex-grow">
                {mainNavItems
                  ?.slice(4, 8)
                  .map(({ text, icon, href }: NavItem, i: number) => {
                    return (
                      <MenuItem href={href} key={i} icon={icon} text={text} />
                    )
                  })}
              </div>
              <div className="c-cc-dropdown__col / flex-grow">
                {mainNavItems
                  ?.slice(8, 12)
                  .map(({ text, icon, href }: NavItem, i: number) => {
                    return (
                      <MenuItem href={href} key={i} icon={icon} text={text} />
                    )
                  })}
              </div>
            </div>
            {navUpsellCard ? (
              <div
                className={`c-cc-dropdown__col shrink-0 / w-72 / pl-7 / border-l border-solid border-gray-300`}
              >
                <NavUpsell
                  cardNavUpsell={navUpsellCard}
                  promotion={{ id: navUpsellCard.slug, name: 'nav.upsell' }}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <style jsx>{`
        .c-cc-dropdown {
          left: 0;
        }
      `}</style>
    </NavLink>
  )
}

const CardIssuer: React.FC<{ navData: NavData }> = ({ navData }) => {
  const mainNavItems = navData?.['card-issuer'].sections?.main.items

  const navLinkHref = navData['credit-cards'].href || '#'

  return (
    <NavLink
      // text={navData['credit-cards'].text}
      text="Card Issuer"
      href={navLinkHref}
      i={0}
      menu="card-issuer"
    >
      <div className="c-cc-dropdown / flex shrink-0 / w-full px-7 py-6">
        <div className="c-cc-dropdown__content / flex / w-full">
          <div className="c-cc-dropdown__innner base / flex / w-full">
            <div className="c-cc-dropdown__col / flex-grow">
              {mainNavItems
                ?.slice(0, 3)
                .map(({ text, icon, href }: NavItem, i: number) => {
                  return (
                    <MenuItem href={href} key={i} icon={icon} text={text} />
                  )
                })}
            </div>
            <div className="c-cc-dropdown__col / flex-grow">
              {mainNavItems
                ?.slice(3, 6)
                .map(({ text, icon, href }: NavItem, i: number) => {
                  return (
                    <MenuItem href={href} key={i} icon={icon} text={text} />
                  )
                })}
            </div>
            <div className="c-cc-dropdown__col / flex-grow">
              {mainNavItems
                ?.slice(6, 9)
                .map(({ text, icon, href }: NavItem, i: number) => {
                  return (
                    <MenuItem href={href} key={i} icon={icon} text={text} />
                  )
                })}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .c-cc-dropdown {
          left: 0;
          width: 100%;
        }
      `}</style>
    </NavLink>
  )
}

const CreditRange: React.FC<{ navData: NavData }> = ({ navData }) => {
  const mainNavItems = navData?.['credit-range'].sections?.main.items

  const navLinkHref = navData['credit-range'].href || '#'

  return (
    <NavLink
      // text={navData['credit-cards'].text}
      text="Credit Range"
      href={navLinkHref}
      i={0}
      menu="credit-range"
    >
      <div className="c-cc-dropdown / flex shrink-0 / w-full px-7 py-6">
        <div className="c-cc-dropdown__content / flex / w-full">
          <div className="c-cc-dropdown__innner base / flex items-center / w-full">
            <div className="c-cc-dropdown__col / flex-grow / pr-7">
              {mainNavItems?.map(({ text, icon, href }: NavItem, i: number) => {
                return <MenuItem href={href} key={i} icon={icon} text={text} />
              })}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .c-cc-dropdown {
          left: 0;
          width: 100%;
        }
      `}</style>
    </NavLink>
  )
}

const NavUpsell: React.FC<{
  cardNavUpsell: CompareCredit.FormattedCard | null
  promotion: CompareCredit.Promotion
}> = ({ cardNavUpsell, promotion }) => {
  const orderId = useMemo(() => generateNewOrderId(), [])

  const cardNavUpsellText =
    cardNavUpsell?.navUpsellBannerText || 'Featured Card'

  const handleClick = () => {
    clickPromotion({
      name: promotion.name,
      promotion_id: promotion.id,
    })
  }

  return (
    <div className="c-nav-upsell / flex flex-col / text-center">
      <div className="flex items-center justify-center / text-center sm:text-left mb-2">
        <p className="c-ribbon c-ribbon--slimmer / relative inline-block / px-4 / text-xs font-bold text-primary leading-snug uppercase / bg-tetriary">
          <span className="inline-block pt-0.5 overflow-hidden">
            {cardNavUpsellText}
          </span>
        </p>
      </div>
      <div className="flex">
        <div className="c-card-art__container / w-32 / mx-auto mb-2">
          <div className="c-card-art / relative / transition-25s">
            <div className="c-card-art__container / relative / w-full / rounded-sm shadow overflow-hidden text-center">
              {cardNavUpsell && (
                <LinkToRedirect
                  linkParams={cardNavUpsell.applyNowLinkParameters}
                  orderId={orderId}
                  slug={cardNavUpsell.slug}
                  externalId="702"
                >
                  <button
                    className="relative flex / w-full / overflow-hidden rounded-sm shadow z-0"
                    onClick={handleClick}
                  >
                    <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white lg:text-lg">
                      <span className="c-card-art__icon / w-full">
                        <span className="inline-block w-4 h-4">
                          <Image
                            src="/static/icons/icon-lock-round-w.svg"
                            alt="lock"
                            height={16}
                            width={16}
                            style={nextImageHelper.responsive}
                            sizes="100vw"
                          />
                        </span>
                      </span>
                      <span className="w-full font-semibold text-fs13">
                        Apply Now
                      </span>
                    </span>
                    {cardNavUpsell && (
                      <CardArt
                        _rev={cardNavUpsell._rev}
                        categoryId="702"
                        externalId="702"
                        cardArt={cardNavUpsell.cardArt}
                        imgClasses="relative z-0 w-full h-full object-cover top-0  bg-dark"
                        customCodeSnippets={cardNavUpsell.customCodeSnippets}
                        issuer={cardNavUpsell.issuer.slug.current}
                        name={cardNavUpsell.name}
                        orderId={orderId}
                        slug={cardNavUpsell.slug}
                      />
                    )}
                  </button>
                </LinkToRedirect>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className="max-w-xs / mb-1 / text-xs text-center leading-tight">
          {cardNavUpsell && (
            <LinkToRedirect
              linkParams={cardNavUpsell.applyNowLinkParameters}
              orderId={orderId}
              externalId="702"
              slug={cardNavUpsell.slug}
            >
              <button
                onClick={handleClick}
                className="text-primary-mid font-semibold / hover:text-primary-bright"
                dangerouslySetInnerHTML={{
                  __html: supify(cardNavUpsell.name),
                }}
              />
            </LinkToRedirect>
          )}
        </p>
      </div>
      <div className="mb-1">
        {cardNavUpsell && (
          <StarRatingEditor
            productSlug={cardNavUpsell.slug}
            stars={cardNavUpsell.expertReviewRating}
          />
        )}
      </div>
      <div className="px-3">
        {cardNavUpsell && (
          <LinkToRedirect
            linkParams={cardNavUpsell.applyNowLinkParameters}
            orderId={orderId}
            externalId="702"
            slug={cardNavUpsell.slug}
          >
            <button
              onClick={handleClick}
              className="c-btn c-btn--primary / max-w-13rem w-9/12 / mb-2 py-1.5 / leading-tighter text-base"
            >
              Apply Now
              <span className="c-btn__icon / inline-block / w-4 h-4 ml-2">
                <Image
                  src="/static/icons/icon-lock-round-w.svg"
                  alt="lock"
                  width={16}
                  height={16}
                  style={nextImageHelper.intrinsic}
                />
              </span>
            </button>
          </LinkToRedirect>
        )}{' '}
        <p className="text-xs text-gray-600">
          {cardNavUpsell ? (
            <span>
              <SecureSite
                issuerName={cardNavUpsell.issuer.name}
                issuerSecureSite={cardNavUpsell.issuerSecureSite}
              />
            </span>
          ) : (
            ``
          )}
        </p>
        {cardNavUpsell && (
          <>
            {cardNavUpsell.ratesAndFees &&
              cardNavUpsell.ratesAndFees !== 'N/A' && (
                <div className="pt-1">
                  <a
                    className="inline-block / w-full / text-xs text-center text-primary-bright leading-snug / hover:underline hover:text-primary-mid"
                    href={cardNavUpsell.ratesAndFees}
                    onClick={() => {
                      clickRatesAndFees({
                        applyNowLink: cardNavUpsell.applyNowLink,
                        component: 'Modal Leave Behind Tile',
                        name: cardNavUpsell.name,
                        url: window.location.href,
                      })
                    }}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Rates &amp; Fees
                  </a>
                  <>
                    {cardNavUpsell.issuer.slug.current ===
                      'american-express' && (
                      <p className="text-fs10 text-gray-600 / text-center / mb-3 md:mb-1 / w-full leading-4">
                        Terms Apply
                      </p>
                    )}
                  </>
                </div>
              )}
          </>
        )}
      </div>
      <div className="absolute bottom-[22px] right-[12px]">
        <LabelAd />
      </div>
    </div>
  )
}

const Bbb: React.FC = () => {
  return (
    <div className="c-nav-main__bbb / flex items-center justify-center shrink-0 / w-auto">
      <div className="c-bbb c-bbb--base hidden lg:block w-28">
        <Image
          src="/static/bbb.svg"
          width={112}
          height={56}
          alt="Better Business Bureau badge"
          priority={true}
          style={nextImageHelper.responsive}
          sizes="100vw"
        />
      </div>
      <div className="c-bbb c-bbb--base--white hidden w-28">
        <Image
          src="/static/logos/bbb-base-white.svg"
          width={94}
          height={33}
          priority={true}
          alt="Better Business Bureau badge"
          style={nextImageHelper.responsive}
          sizes="100vw"
        />
      </div>
    </div>
  )
}

const BbbMobile: React.FC = () => {
  return (
    <div className={`c-nav-main__bbb flex flex-col justify-end items-end`}>
      <div className="c-bbb c-bbb--minimal w-9">
        <Image
          src="/static/bb-logo.svg"
          height={36}
          width={36}
          alt="BBB logo"
          priority={true}
          style={nextImageHelper.responsive}
          sizes="100vw"
        />
      </div>
      <div className="c-bbb c-bbb--minimal--white w-9">
        <Image
          src="/static/logos/bbb-minimal-white.svg"
          height={36}
          width={36}
          alt="BBB logo"
          priority={true}
          style={nextImageHelper.responsive}
          sizes="100vw"
        />
      </div>
    </div>
  )
}

const NavLinkContainer: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <ul
      className="mr-8 hidden lg:inline-flex font-sans text-sm"
      style={{ color: navLinkColor }}
    >
      {children}
    </ul>
  )
}
