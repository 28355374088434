import { useStatsigClient, useFeatureGate } from '@statsig/react-bindings'
import isEmpty from 'lodash/fp/isEmpty'
import Image from 'next/image'
import Router from 'next/router'
import { useCallback, useContext, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { modalExtensionClosed, modalExtensionOpened } from 'clients/segment'
import {
  CardCart,
  CookieDisclaimer,
  CreditModal,
  Footer,
  FooterMin,
  NavBar,
  SortSelect,
  UserContext,
  ModalLeaveBehindContext,
  ModalLeaveBehindExtension,
  ModalLeaveBehindCard,
} from 'components/'
import MultistepBusinessForm from 'components/business-modal'
import { MobileNav } from 'components/navigation/mobile-nav'
import { useFetchCardsSlug } from 'utils/'
import cn from 'utils/classnames'
import { getUrl } from 'utils/getURL'
import { generateNewOrderId } from 'utils/orderId/generateNewOrderId'
import { useExperiment } from 'utils/use-experiment-wrapper'
import type { CompareCredit } from '../../../types'
// 2023.5: EXP-135 Leave Behind

let hasPageLoaded = false

const Navigation: React.FC<{
  alert: {
    height: number
    setHeight: React.Dispatch<React.SetStateAction<number>>
    active: boolean
    show: boolean
    setShowAlert: React.Dispatch<React.SetStateAction<boolean>>
    message: any[] | undefined
  }
  banner: React.ReactNode | undefined
  disclaimerAlignment?: 'left' | 'right' | 'top' | string
  hideAsSeenIn?: boolean
  hideBBBIcon?: boolean
  lightTheme?: boolean
  mixTheme?: boolean
  navNone?: boolean
  navType?: string
  onHome: boolean
  setShowAlertUpsell: React.Dispatch<React.SetStateAction<boolean>>
  upsellBanner?: CompareCredit.NavUpsellBanner
}> = ({
  alert,
  banner,
  disclaimerAlignment,
  hideAsSeenIn,
  hideBBBIcon,
  lightTheme,
  mixTheme,
  onHome,
  navType,
  setShowAlertUpsell,
  upsellBanner,
}) => {
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false)
  const [selectedSubMenu, setSelectedSubMenu] = useState<string | null>(null)

  return (
    <>
      <NavBar
        alert={alert}
        banner={banner}
        disclaimerAlignment={disclaimerAlignment}
        hideAsSeenIn={hideAsSeenIn}
        hideBBBIcon={hideBBBIcon}
        lightTheme={lightTheme}
        mixTheme={mixTheme}
        onHome={onHome}
        setShowMobileNav={setShowMobileNav}
        showMobileNav={showMobileNav}
        setSelectedSubMenu={setSelectedSubMenu}
        navType={navType}
        setShowAlertUpsell={setShowAlertUpsell}
        upsellBanner={upsellBanner}
      />
      <MobileNav
        alert={{
          height: alert.height,
          show: alert.show,
        }}
        show={showMobileNav}
        selectedSubMenu={selectedSubMenu}
        setSelectedSubMenu={setSelectedSubMenu}
        setShowMobileNav={setShowMobileNav}
      />
    </>
  )
}

export const LayoutMain: React.FC<{
  alert?: {
    active: boolean
    message: any[] | undefined
  }
  banner?: React.ReactNode
  children: React.ReactNode
  comparisonDisabled?: boolean
  disclaimerAlignment?: 'left' | 'right' | 'top' | string
  extraClasses?: string
  footerType?: string
  hideAsSeenIn?: boolean
  hideBBBIcon?: boolean
  lightTheme?: boolean
  mixTheme?: boolean
  mobileFooterTileHeight?: number
  navNone?: boolean
  navType?: string
  onHome?: boolean
  showBackButton?: boolean
  hideBackButtonInComparisionPage?: boolean
  upsellBanner?: CompareCredit.NavUpsellBanner
  modalLeaveBehindCard?: CompareCredit.ModalLeaveBehindCard
  isModalLeaveBehindCard?: boolean
  slug?: string
}> = (props) => {
  const {
    alert = { active: false, message: undefined },
    disclaimerAlignment,
    upsellBanner,
    isModalLeaveBehindCard,
    modalLeaveBehindCard,
  } = props

  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertHeight, setAlertHeight] = useState<number>(0)
  const [showAlertUpsell, setShowAlertUpsell] = useState<boolean>(false)
  const { pathname, query } = getUrl()
  const { logEvent } = useStatsigClient()

  const { comparisonCart } = useContext(UserContext)

  // show/hide alert when alert.active changes
  // this should force a change every time a new category is selected
  useEffect(() => setShowAlert(alert.active), [alert.active])
  useEffect(() => {
    // Allows us to ensure we only log the page_event one time
    // LayoutMain currently re-renders so a typical [] useEffect
    // does not work by itself.
    if (!hasPageLoaded) {
      hasPageLoaded = true
      logEvent('page_loaded', pathname || undefined, query || undefined)
    }
  }, [])

  const { value: showSortSelect } = useFeatureGate('select_sort')

  // Leave behind modal
  const {
    openModalLeaveBehindExtension,
    setOpenModalLeaveBehindExtension,
    shouldOpenModalLeaveBehindCapOneShopping,
    setIsModalLeaveBehindCard,
  } = useContext(ModalLeaveBehindContext)

  const onCloseModalLeaveBehindExtension = useCallback(() => {
    setOpenModalLeaveBehindExtension(false)
    modalExtensionClosed()
    logEvent('modal_leave_behind_extension_closed')
  }, [setOpenModalLeaveBehindExtension])

  useEffect(() => {
    if (openModalLeaveBehindExtension) {
      modalExtensionOpened()
    }
  }, [openModalLeaveBehindExtension])

  /* ========== LBM - CapOne ========== */
  const {
    modalLeaveBehindOrderId,
    openModalLeaveBehind,
    setModalLeaveBehindId,
    setModalLeaveBehindSlug,
    setModalLeaveBehindOrderId,
    onCloseModalLeaveBehind,
  } = useContext(ModalLeaveBehindContext)

  const externalId = '00'
  const categoryId = '00'
  const sortParams = { categoryId: externalId }
  const slug = ['cap-one-shopping']
  const { cards } = useFetchCardsSlug(slug, sortParams)
  const capOneShopping = cards[0] as CompareCredit.FormattedCard

  useEffect(() => {
    if (
      capOneShopping &&
      shouldOpenModalLeaveBehindCapOneShopping &&
      !isModalLeaveBehindCard
    ) {
      setModalLeaveBehindId(uuid())
      setModalLeaveBehindOrderId(generateNewOrderId())
      setModalLeaveBehindSlug(capOneShopping.slug)
      setIsModalLeaveBehindCard(true)
    }
  }, [
    capOneShopping,
    shouldOpenModalLeaveBehindCapOneShopping,
    isModalLeaveBehindCard,
  ])

  /* ===== 2024.09: EXP-325 Venture Banner Upsell on Travel Category Page ===== */
  const { experiment: configExp325, isLoading: isLoadingExp325 } =
    useExperiment('exp_325_travel_venture_banner')
  const variationExp325 = configExp325?.value?.name || 'control'

  return (
    <>
      <div
        id="layout-main"
        className={cn(
          'c-layout-main flex flex-col min-h-screen justify-between ',
          props.extraClasses,
          {
            'c-layout-main--324-control':
              !isLoadingExp325 && variationExp325 === 'control',
            'c-layout-main--324-v1':
              !isLoadingExp325 &&
              variationExp325 === 'v1-hide-travel-venture-banner',
          },
        )}
      >
        <a
          className="c-skip-link / sr-only focus:not-sr-only"
          href="#main-content"
        >
          Skip to main content
        </a>
        {props.navNone ? (
          ''
        ) : (
          <Navigation
            alert={{
              active: alert.active,
              height: alertHeight,
              message: alert.message,
              setHeight: setAlertHeight,
              setShowAlert: setShowAlert,
              show: showAlert,
            }}
            banner={props.banner}
            disclaimerAlignment={disclaimerAlignment}
            hideAsSeenIn={props.hideAsSeenIn}
            hideBBBIcon={props.hideBBBIcon}
            lightTheme={props.lightTheme}
            mixTheme={props.mixTheme}
            navType={props.navType}
            onHome={props.onHome || false}
            setShowAlertUpsell={setShowAlertUpsell}
            upsellBanner={upsellBanner}
          />
        )}
        {showSortSelect && <SortSelect />}
        <div
          className={`c-main__container transition-25s ${
            !isLoadingExp325 && showAlertUpsell
              ? 'pt-40 xs:pt-36 sm:pt-[7.5rem] lg:pt-[8.5rem]'
              : `pt-16 lg:pt-20`
          } ${
            disclaimerAlignment == 'top' && `c-main__container--disclaimer-top`
          }`}
          style={{
            marginTop: showAlert ? alertHeight : 0,
          }}
        >
          <main
            id="main-content"
            className={`c-main / container-fluid text-dark ${
              showSortSelect && 'c-sort-select-desktop'
            }`}
          >
            {props.children}
          </main>{' '}
        </div>
        <CookieDisclaimer />
        {props.footerType === 'minimal' ? <FooterMin /> : <Footer />}
        <CreditModal />
        {props.showBackButton && !props.hideBackButtonInComparisionPage && (
          <div className="c-cart is-on / flex w-full / fixed z-30 bottom-0 left-0 / sm:ml-21 sm:mb-21 / transition-all--25">
            <div className="c-cart__row / relative / flex content-start items-center justify-start / w-full / px-4 py-2  lg:px-4 / bg-white shadow-2xl">
              <button
                className="c-cart__btn-reset flex items-center / w-16 h-10 pr-4 / text-gray-600 text-sm sm:text-base / hover:text-primary-bright focus:outline-none / transition-all"
                onClick={() => Router.back()}
              >
                <div className="shrink-0 inline-block mr-2 / opacity-50 / w-4 h-4">
                  <Image
                    alt="chevron left"
                    src="/static/icons/icon-chevron-l-black.svg"
                    height={16}
                    width={16}
                  />
                </div>
                Back
              </button>
            </div>
          </div>
        )}
      </div>

      <MultistepBusinessForm />

      {shouldOpenModalLeaveBehindCapOneShopping ? (
        <div className="relative z-80">
          {modalLeaveBehindOrderId && (
            <ModalLeaveBehindCard
              modalLeaveBehindCardProduct={capOneShopping}
              modalLeaveBehindCardTitle={''}
              categoryId={categoryId}
              externalId={externalId}
              orderId={modalLeaveBehindOrderId}
              cardOrderIds={{ [capOneShopping.slug]: modalLeaveBehindOrderId }}
              referencedCards={null}
              open={openModalLeaveBehind}
              onClose={onCloseModalLeaveBehind}
            />
          )}
        </div>
      ) : isModalLeaveBehindCard ? (
        <div className="relative z-80">
          {modalLeaveBehindOrderId &&
            modalLeaveBehindCard &&
            modalLeaveBehindCard.referencedCards &&
            modalLeaveBehindCard.cardOrderIds && (
              <ModalLeaveBehindCard
                modalLeaveBehindCardProduct={
                  modalLeaveBehindCard.referencedCards[
                    modalLeaveBehindCard.modalLeaveBehindCardProduct.slug
                  ] as CompareCredit.FormattedCard
                }
                modalLeaveBehindCardTitle={
                  modalLeaveBehindCard.modalLeaveBehindCardTitle
                }
                modalLeaveBehindCardContent={
                  modalLeaveBehindCard.modalLeaveBehindCardContent
                }
                modalLeaveBehindCardDeclineText={
                  modalLeaveBehindCard.modalLeaveBehindCardDeclineText
                }
                categoryId={categoryId}
                externalId={externalId}
                orderId={modalLeaveBehindOrderId}
                cardOrderIds={modalLeaveBehindCard.cardOrderIds}
                referencedCards={modalLeaveBehindCard.referencedCards}
                open={openModalLeaveBehind}
                onClose={onCloseModalLeaveBehind}
              />
            )}
        </div>
      ) : (
        ''
      )}

      <ModalLeaveBehindExtension
        onClose={onCloseModalLeaveBehindExtension}
        open={openModalLeaveBehindExtension}
      />

      {!props.showBackButton ? (
        <CardCart />
      ) : (
        <>
          {!props.comparisonDisabled && !isEmpty(comparisonCart) && (
            <CardCart />
          )}
        </>
      )}

      <style jsx>{`
        /* T-DISCLAIMER-TOP: Theme used to display the advertiser disclosure
        at the top of the tips pages
        ======================================================= */
        .c-main__container--disclaimer-top {
          padding-top: 5.5rem;
        }
        @media (min-width: 1024px) {
          .c-main__container--disclaimer-top {
            padding-top: 6.5rem;
          }
        }
      `}</style>
    </>
  )
}
