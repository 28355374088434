import Image from 'next/image'
import { useMemo } from 'react'
import type { OverrideProperties } from 'type-fest'

import {
  AdvertiserDisclaimer,
  LabelAd,
  LinkToRedirect,
  StarRatingEditor,
} from 'components/'
import { CardArt } from 'components/card-art'
import { SecureSite } from 'components/secure-site'
import { nextImageHelper, supify } from 'utils/'
import { getIsNonEmptyArray } from 'utils/getIsNonEmptyArray'
import { generateNewOrderId } from 'utils/orderId/generateNewOrderId'
import type { CompareCredit } from '../../../../types/compare-credit'

const CATEGORY_ID = '53'
const EXTERNAL_ID = '53'
const PROFILE_ID = 'hpto'

type HeroHptoProps = {
  cards: CompareCredit.FormattedCard[]
  headline: string
}

export const HeroHpto: React.FC<HeroHptoProps> = ({ cards, ...restProps }) => {
  const hasCards = getIsNonEmptyArray(cards)

  if (!hasCards) {
    throw new Error(
      'HeroHpto requires a non-empty array of cards, but received an empty array',
    )
  }

  return <HeroHptoInner {...restProps} cards={cards} />
}

const HeroHptoInner: React.FC<
  OverrideProperties<HeroHptoProps, { cards: [CompareCredit.FormattedCard] }>
> = ({ cards: [card] }) => {
  const orderId = useMemo(() => generateNewOrderId(), [])
  const applyNowLinkParameters =
    card.applyNowLinkProfiles[PROFILE_ID]?.applyNowLinkParameters

  return (
    <div
      id="hero"
      className="c-hero c-hero--wf c-hero--hpto-light-links / w-full-vw / -mt-18 lg:-mt-24 / pt-20 md:pt-24 lg:pt-28 / pb-8 md:pb-0 / relative z-10"
    >
      <div className="w-full">
        <div className="container-fluid / md:max-w-3xl lg:max-w-none xl:max-w-6xl / xl:px-8 / pt-4 md:pt-6 lg:pt-10">
          <div className="c-hero__row-1 c-animate-1 relative z-1 / w-full / mb-6">
            <div className="md:flex md:flex-row md:items-start">
              <div className="md:w-3/5 xl:w-[62%] md:pr-12 xl:pr-8">
                <h1
                  className="mb-3 / leading-tight text-center md:text-left u-wf-red / 
                text-[31px] xs:text-[32px] sm:text-[40px] md:text-[34px] lg:text-[48px] xl:text-[56px]"
                >
                  Say <b>goodbye</b> to{' '}
                  <span className="block lg:inline">
                    interest for <b>21&nbsp;months</b>
                  </span>
                </h1>
                <p className="mb-5 lg:mb-8 text-lg sm:text-xl lg:text-2xl xl:text-[30px] / lg:w-11/12 / u-wf-red text-center md:text-left">
                  Enjoy 21 months of low interest on purchases and
                  balance&nbsp;transfers
                </p>
                <div className="c-hero__card-container c-animate-2 relative z-1 / md:hidden">
                  <div className="relative / flex justify-end / max-w-xs xl:max-w-none md:mr-0 mx-auto / mb-5 ">
                    <CardTile
                      card={card}
                      orderId={orderId}
                      categoryId={CATEGORY_ID}
                      externalId={EXTERNAL_ID}
                    />
                  </div>
                </div>
                <div className="c-animate-3 relative / max-w-md md:w-72 lg:w-96 / mx-auto md:ml-0">
                  <LinkToRedirect
                    linkParams={applyNowLinkParameters}
                    orderId={orderId}
                    profileId={PROFILE_ID}
                    slug={`${card.slug}`}
                    externalId={EXTERNAL_ID}
                  >
                    <div
                      className="c-btn / bg-tetriary hover:bg-tetriary-dark u-wf-red / w-full max-w-none / mb-2.5 md:mb-4 lg:mb-4 / py-2.5 / 
                    font-bold text-xl md:text-lg lg:text-xl xl:text-2xl"
                    >
                      Apply Now
                      <span className="w-5 / ml-2">
                        <Image
                          width={16}
                          height={16}
                          alt="lock icon"
                          src="/static/icons/icon-lock-round-wf-red.svg"
                          aria-hidden={true}
                          sizes="100vw"
                          style={nextImageHelper.responsive}
                        />
                      </span>
                    </div>
                  </LinkToRedirect>
                  <p className="mb-0.5 / text-fs12 lg:text-sm / text-center leading-tight / text-shadow opacity-60">
                    <SecureSite
                      issuerName={card.issuer.name}
                      issuerSecureSite={card.issuerSecureSite}
                    />
                  </p>
                  {card.ratesAndFees && (
                    <div className="w-full / text-center text-shadow">
                      {card.ratesAndFees !== 'N/A' && (
                        <p className="mb-0.5">
                          <a
                            className="inline-block text-fs12 lg:text-sm leading-snug / u-wf-red"
                            href={card.ratesAndFees}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Rates &amp; Fees
                          </a>
                        </p>
                      )}
                      {card.issuer.slug.current === 'american-express' && (
                        <p className="text-fs12 / text-center / w-full leading-snug text-slate-700">
                          Terms Apply
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="c-hero__card-container c-animate-2 relative z-1 / md:w-2/5 xl:w-[38%] / hidden md:block">
                <div className="relative / flex justify-end / max-w-xs md:max-w-none md:mr-0 mx-auto / mb-5">
                  <CardTile
                    card={card}
                    orderId={orderId}
                    categoryId={CATEGORY_ID}
                    externalId={EXTERNAL_ID}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="c-hero__row-2 c-animate-3 relative">
            <div
              className="c-hero__list-wrap / max-w-md md:max-w-none / mb-4 xl:mb-5 mx-auto / pl-2 pr-4 md:pl-6 md:pr-6 lg:px-0 py-1 md:py-2 xl:py-5 / 
            rounded u-wf-shadow"
            >
              <ul className="c-hero__list / lg:flex lg:flex-row / text-white text-sm sm:text-base lg:text-sm xl:text-base">
                <li className="c-hero__list-item / flex flex-row items-center / lg:w-1/3 / py-3 lg:px-4">
                  <span className="shrink-0 / w-12 md:w-14 / mr-3 md:mr-4 lg:mr-3">
                    <Image
                      width={16}
                      height={16}
                      alt="money growth"
                      src="/static/icons/icon-coins-growth.svg"
                      aria-hidden={true}
                      sizes="100vw"
                      style={nextImageHelper.responsive}
                    />
                  </span>{' '}
                  <span className="xl:pr-2">
                    0% intro APR 21 months from account opening on purchases and
                    qualifying balance transfers ({card.aprBalanceTransfer}{' '}
                    thereafter)
                  </span>
                </li>
                <li className="c-hero__list-item / flex flex-row items-center / lg:w-1/3 / py-3 lg:px-4 xl:pr-0">
                  <span className="shrink-0 / w-12 md:w-14 / mr-3 md:mr-4 lg:mr-3">
                    <Image
                      width={16}
                      height={16}
                      alt="mobile protection"
                      src="/static/icons/icon-mobile-protection.svg"
                      aria-hidden={true}
                      sizes="100vw"
                      style={nextImageHelper.responsive}
                    />
                  </span>{' '}
                  <span className="pr-4 lg:pr-0">
                    Get up to $600 of cell phone protection against damage or
                    theft, subject to a $25&nbsp;deductible
                  </span>
                </li>
                <li className="c-hero__list-item / flex flex-row items-center / lg:w-1/3 / py-3 lg:px-4">
                  <span className="shrink-0 / w-12 md:w-14 / mr-3 md:mr-4 lg:mr-3">
                    <Image
                      width={16}
                      height={16}
                      alt="no fee"
                      src="/static/icons/icon-cash-coins-no-fees.svg"
                      aria-hidden={true}
                      sizes="100vw"
                      style={nextImageHelper.responsive}
                    />
                  </span>{' '}
                  <span>
                    Reap the benefits of this card with no annual&nbsp;fee
                  </span>
                </li>
              </ul>
            </div>
            <div className="flex flex-row justify-between / max-w-md md:max-w-none mx-auto / px-3 / leading-tight">
              <AdvertiserDisclaimer
                alignPopup="t-disclosure-left"
                disclosureTextAlign="left"
              />
              <LabelAd />
            </div>
          </div>
        </div>
      </div>

      <style jsx>
        {`
          /* ===== ANIMATION ===== */
          .c-animate-1,
          .c-animate-2,
          .c-animate-3 {
            top: 2rem;
            opacity: 0;
            animation-name: fadeInOpacity, animateTopNorthRelative;
            animation-duration: 1.5s;
            animation-fill-mode: forwards;
            animation-timing-function: ease-in-out;
          }
          .c-animate-2 {
            animation-delay: 0.25s;
          }
          .c-animate-3 {
            animation-delay: 0.5s;
          }

          /* ===== UTILITY ===== */
          .u-wf-shadow {
            box-shadow: 0px 4px 15px 0px rgba(53, 0, 0, 0.15);
          }
          .u-wf-red {
            color: #942a37;
          }
          .u-wf-red:hover {
            color: rgb(126, 13, 26);
          }
          .u-wf-red-link {
            color: #d41c2c;
          }
          .u-wf-red-link:hover {
            color: rgb(141, 4, 15);
          }

          /* ===== HERO ===== */
          .c-hero {
            background-image: url('/static/images/bg-wf-reflect-202502.jpg');
            background-size: cover;
            background-position: left bottom;
            background-repeat: no-repeat;
          }
          .c-hero__list-wrap {
            background: linear-gradient(92deg, #cf2920 0%, #4d0008 80%);
          }
          .c-hero__list-item {
            border-image: linear-gradient(
                to right,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.4) 50%,
                rgba(255, 255, 255, 0) 100%
              )
              1;
            border-bottom-width: 1px;
            border-style: solid;
          }
          .c-hero__list-item:last-child {
            border-bottom: none;
            border-right: none;
          }
          @media (min-width: 640px) {
            .c-hero {
              background-size: 220% 100%;
              background-position: 90% bottom 10%;
            }
          }
          @media (min-width: 768px) {
            .c-hero {
              background-image: url('/static/images/bg-wf-reflect-202502-lg.jpg');
              background-size: cover;
              background-position: center bottom;
            }
          }
          @media (min-width: 1024px) {
            .c-hero__list-item {
              border-bottom: none;
            }
            .c-hero__list-item {
              border-image: linear-gradient(
                  to bottom,
                  rgba(255, 255, 255, 0) 0%,
                  rgba(255, 255, 255, 0.4) 50%,
                  rgba(255, 255, 255, 0) 100%
                )
                1;
              border-right-width: 1px;
            }
          }
          @media (min-width: 1800px) {
            .c-hero {
              background-size: 120% 100%;
            }
          }
        `}
      </style>
    </div>
  )
}

const CardTile: React.FC<{
  card: CompareCredit.FormattedCard
  categoryId: string
  externalId: string
  orderId: string
}> = ({ card, categoryId, externalId, orderId }) => {
  const applyNowLinkParameters =
    card.applyNowLinkProfiles[PROFILE_ID]?.applyNowLinkParameters

  return (
    <div
      className={`flex flex-col items-center justify-center / w-full m-auto`}
    >
      <div className="flex flex-col justify-center items-stretch / w-full / -mb-3">
        <div className="c-card-art / relative / w-full / mb-2.5 lg:mb-3.5 / transition-25s / rounded">
          <div className="c-card-art__container / relative / rounded-lg text-center">
            <LinkToRedirect
              linkParams={applyNowLinkParameters}
              orderId={orderId}
              profileId={PROFILE_ID}
              slug={`${card.slug}`}
              externalId={externalId}
            >
              <div className="relative flex overflow-hidden rounded-lg lg:rounded-xl lg:rounded z-0 u-wf-shadow">
                <span className="c-card-art__shine / absolute z-2 / w-16 / rotate-12"></span>
                <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-1 left-0 / w-full h-full text-white lg:text-xl xl:text-2xl">
                  <span className="w-full">
                    <span className="inline-block w-8 lg:w-10">
                      <Image
                        width={16}
                        height={16}
                        alt="lock"
                        src="/static/icons/icon-lock-round-w.svg"
                        aria-hidden={true}
                        style={nextImageHelper.responsive}
                        sizes="100vw"
                      />
                    </span>
                  </span>
                  <span className="w-full font-semibold">Apply Now</span>
                </span>
                <CardArt
                  _rev={card._rev}
                  cardArt={card.cardArt}
                  imgClasses="relative z-0 w-full h-full object-cover top-0"
                  issuer={card.issuer.slug.current}
                  name={card.name}
                  orderId={orderId}
                  slug={card.slug}
                  customCodeSnippets={card.customCodeSnippets}
                  categoryId={categoryId}
                  externalId={externalId}
                  imagePriority={true}
                />
              </div>
            </LinkToRedirect>
          </div>
        </div>
        <p className="mb-1 lg:mb-2 / text-base lg:text-xl font-semibold text-center leading-snug">
          <LinkToRedirect
            externalId={externalId}
            linkParams={card.applyNowLinkParameters}
            orderId={orderId}
            query={undefined}
            slug={card.slug}
          >
            <span
              className="u-wf-red-link / hover:underline "
              dangerouslySetInnerHTML={{
                __html: supify(card.name),
              }}
            />
          </LinkToRedirect>
        </p>
        <div className="text-center / lg:scale-[1.15]">
          <StarRatingEditor
            productSlug={card.slug}
            stars={card.expertReviewRating}
          />
        </div>
      </div>

      <style jsx>{`
        /* ===== UTILITY ===== */
        .u-wf-shadow {
          box-shadow: 0px 4px 15px 0px rgba(53, 0, 0, 0.15);
        }
        .u-wf-red {
          color: #942a37;
        }
        .u-wf-red:hover {
          color: rgb(126, 13, 26);
        }
        .u-wf-red-link {
          color: #d41c2c;
        }
        .u-wf-red-link:hover {
          color: rgb(141, 4, 15);
        }

        /* ===== CARD ART ===== */
        .c-card-art__content {
          background-color: rgba(27, 14, 11, 0.75);
        }
        .c-card-art__container {
          box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
        }
        .c-card-art__shine {
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 100%
          );
          display: block;
          height: 100%;
          left: -75%;
          position: absolute;
          top: 0;
          transform: skewX(-25deg);
          width: 50%;
          z-index: 2;
          animation: shine 6s infinite;
          animation-delay: 2s;
        }

        @keyframes shine {
          0% {
            left: 0;
          }
          30% {
            left: 125%;
          }
          100% {
            left: 125%;
          }
        }
      `}</style>
    </div>
  )
}
